<template>
  <v-row class="passport-box">
    <v-col cols="12" lg="5" sm="12" class="text-center">
      <ImageReview
        class="mx-auto my-2"
        :nid="page?.rels?.nid"
        :content-type="page.typename"
        :download-nick="nick"
        :software-title="soft"
        :alt="alt || 'review'"
      />

      <v-row class="text-center">
        <v-col v-if="welcomeBonus" sm="12">
          <v-row>
            <v-col class="font-weight-bold">
              {{ welcomeBonus }} <span v-if="code"> ({{ code }})</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="page.portal" sm="12" lg="8" class="text-lg-right text-sm-center">
          <Rating v-model="popularity"></Rating>
        </v-col>

        <v-col v-if="page.portal" sm="12" lg="4" class="text-lg-left text-sm-center">
          <span class="rating-circle"> {{ Math.round(popularity / 2) }}/5</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="7" sm="12">
      <Button
        :download-nick="nick"
        :software-title="soft"
        :text="page.downloadTitle"
        class="w-100 text-uppercase font-weight-bold"
      />
      <div class="bg-white pl-2 pb-2">
        <div class="mt-2 pt-2 pb-3 text-capitalize font-weight-bold">More info</div>
        <v-divider v-if="currencies" class="pb-2"></v-divider>
        <v-row v-if="currencies">
          <v-col cols="4">Currencies</v-col>
          <v-col cols="8"><see-more :arr="currencies" /></v-col>
        </v-row>
        <v-divider v-if="paymentMethods" class="pb-2"></v-divider>
        <v-row v-if="paymentMethods">
          <v-col cols="4">Payment Methods</v-col>
          <v-col cols="8"><see-more :arr="paymentMethods" /></v-col>
        </v-row>
        <v-divider v-if="gameTypes" class="pb-2"></v-divider>
        <v-row v-if="gameTypes">
          <v-col cols="4">Types</v-col>
          <v-col cols="8"><see-more :arr="gameTypes" /></v-col>
        </v-row>
        <v-divider v-if="gameThemes" class="pb-2"></v-divider>
        <v-row v-if="gameThemes">
          <v-col cols="4">Themes</v-col>
          <v-col cols="8"><see-more :arr="gameThemes" /></v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script setup>
const page = inject("page");
const rels = unref(page).rels;
const nick = rels?.downloadNick || "";
const soft = rels?.softwareTitle || "";
const welcomeBonus = _.get(rels, ["Welcome Bonus", "title"], "");
const code = _.get(rels, ["Welcome Bonus", "code"], "");
const currencies = rels?.Currencies || "";
const paymentMethods = rels?.["Payment Methods"] || "";
const gameTypes = rels?.Type || "";
const gameThemes = rels?.Themes || "";
const alt = rels?.Title || "";
const popularity = rels?.Popularity || 1;
const supportOptions = computed(() => {
  const tmp = [];
  _.mapKeys(_.get(rels, ["Support options"], {}), function (value, key) {
    tmp.push({ name: _.capitalize(key), color: value ? "#26c280" : "#ff3344", icon: value ? "$check" : "$close" });
  });
  return tmp;
});
</script>
