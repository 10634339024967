<template>
  <v-row class="ma-auto">
    <v-col v-if="welcomeBonus" class="font-weight-bold text-center text-yellowColor text-h6 my-auto" cols="12" md="4">
      {{ welcomeBonus }}
    </v-col>
    <v-col v-if="page.portal && rating && welcomeBonus" cols="12" md="4" class="text-center">
      <Rating v-model="rating"></Rating>
    </v-col>
    <v-col
      v-if="nick || soft"
      cols="12"
      sm="12"
      :md="page.portal && rating && welcomeBonus ? '4' : '8'"
      :class="{ 'page.portal && rating && welcomeBonus': 'text-center my-auto' }"
    >
      <Button
        :download-nick="nick"
        :software-title="soft"
        text="Play Now"
        class="w-50 text-uppercase font-weight-bold"
      />
    </v-col>
  </v-row>
  <!-- <v-col cols="12" md="3" :lg="welcomeBonus ? '3' : nick || soft ? '1' : '12'" class="text-right"> -->
  <ScrollToTop class="up" v-bind="$attrs" />
  <!-- </v-col> -->
</template>
<script setup>
const page = inject("page");
const rels = unref(page)?.rels;
const nick = rels?.downloadNick || "";
const soft = rels?.softwareTitle || "";
const rating = rels?.Popularity || 1;
const welcomeBonus = _.get(unref(page), ["rels", "Welcome Bonus", "title"], "");
</script>
